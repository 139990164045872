import { useEffect, useState } from "react";
import Gallery from "./components/Scene/Gallery";
import Globe from "./components/Scene/Globe";
import Header from "./components/Nav/Header";
import Welcome from "./components/Screens/Welcome";
import Instructions from "./components/Screens/Instructions";
import Footer from "./components/Nav/Footer";
import SubmissionForm from "./components/Screens/SubmissionForm";
import DataLookup from "./DataLookup";

export default function App() {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentCity, setCurrentCity] = useState();

  const [patchbayReady, setPatchbayReady] = useState(false);
  const [audioReady, setaudioReady] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);

  const [loading, setLoading] = useState(true);
  const [transitionActive , setTransitionActive] = useState(true);

  const [submissionFormActive, setSubmissionFormActive] = useState(false);

  const [ui, setUi] = useState('welcome');

  const [cities, setCities] = useState([]);
  const [cityCount, setCityCount] = useState();
  const [countryCount, setCountryCount] = useState();

  const [submissions, setSubmissions] = useState([]);

  
  
  useEffect(() => {
    getCities();
    // document.addEventListener('audioOptOut', () => {
    //   confirmWelcome();
    // });
    // document.addEventListener('patchbayReady', () => {
    //   setPatchbayReady(true);
    // })
    // document.addEventListener('audioReady', () => {
    //   setaudioReady(true);
    //   confirmWelcome();
    // });
  }, [])

  useEffect(() => {
    const formScript = document.createElement('script');
    formScript.onload = function () {
      //console.log('IM script')
    };
    formScript.src = "https://dnsl4xr6unrmf.cloudfront.net/js/campaign.js";
    document.head.appendChild(formScript);
  }, [])

  useEffect(() => {
    if (modelsLoaded) {
      setTransitionActive(false);
      setTimeout(() => {
        setLoading(false);
      }, 800)
    }
  }, [modelsLoaded])

  const handleModelsLoaded = () => {
    setModelsLoaded(true);
  }

  const getCities = async () => {
    const res = await fetch('./data/markers.json');

    if (!res.ok) {
      console.log('city error')
    }

    const data = await res.json();

    const cityArray = [];
    const countryArray = [];
    
    data.forEach(submission => {
      const cityMatch = cityArray.find(x => x.name === submission.city);
      if (!cityMatch) {
        cityArray.push({
          name: submission.city,
          lat: submission.lat,
          lng: submission.lng
        })
      }
      const countryMatch = countryArray.find(x => x.name === submission.country);
      if (!countryMatch) {
        countryArray.push({name: submission.country});
      }
    })
    
    setCities(cityArray)
    setCityCount(cityArray.length)
    setCountryCount(countryArray.length)
    setSubmissions(data)
  }

  

  const confirmWelcome = () => {
    setUi('instructions')
    
    window.gtag('event', 'conversion', {
      'send_to': 'GTM-WJLXD2RH/dulpCJbt-OcYEJHku88D',
      'event_callback': function callBack(id) {
        // console.log(id)
      }
    });

    window.gtag('event', 'conversion', {
      'send_to': 'AW-11016257321/xsj_CLu75ecYEKn--oQp',
      'event_callback': function callBack(id) {
        // console.log(id)
      }
    });
  }
  const enterSite = () => {
    setUi('scene')
  }

  const openGallery = (city) => {
    setCurrentCity(city);
    setGalleryOpen(true);
  }

  const closeGallery = () => {
    setGalleryOpen(false);
    setCurrentCity(null);
  }

  const closeSubmissionForm = () => {
    setSubmissionFormActive(false);
  }

  const openSubmissionForm = () => {
    setSubmissionFormActive(true);
  }


  




  return (
    <div>
      
      {cities && cities.length !== 0 && (
        <Globe 
          handleModelsLoaded={handleModelsLoaded}
          openGallery={openGallery}
          cities={cities}
          galleryOpen={galleryOpen}
        />
      )}

      {galleryOpen && currentCity && submissions && (
        <Gallery
          active={galleryOpen}
          closeGallery={closeGallery}
          city={currentCity}
          submissions={submissions}
        />
      )}
      

      {loading && <div className='transition' data-active={transitionActive}></div>}

      

      {ui === 'welcome' && <Welcome loading={loading} confirmWelcome={confirmWelcome} />}
      {ui === 'instructions' && <Instructions enterSite={enterSite} />}

      <Header cityCount={cityCount} countryCount={countryCount} />
      <Footer ui={ui} audioReady={audioReady} openSubmissionForm={openSubmissionForm} />
    </div>
  );
}
