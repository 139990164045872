import classes from './Modal.module.css'
import IconClose from '../Ui/Icons/IconClose'

function Modal(props) {
  return (
    <div
      className={classes.modal}
      data-active={props.active}
      data-position={props.position}
      data-role={props.role}
      data-background={props.background}
      data-size={props.size}
      data-mode={props.mode}
    >
      
      <div className={classes.box}>
        
        <div className={classes.content}>
          {props.children}
        </div>

        {props.allowClose && (
          <div className={classes.close} onClick={props.close}>
            <IconClose />
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal