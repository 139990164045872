import styles from './Footer.module.css';

export default function Footer(props) {
  return (

    <div className={styles.wrapper} data-audioplayer={props.audioReady} data-ui={props.ui}>
      
      <img className={styles.logo} alt='company logo for The Orchard prefixed with the words powered by' src='./images/powered-by-orchard_white.png' />

      <div>© 2023 The Orchard. All Rights Reserved.</div>
      <div className={styles.legal}>
        <a href="https://www.sonymusic.com/feedback/" target="_blank" rel="noopener">
          Send Us Feedback
        </a>
        <div>|</div>
        <a href="https://www.theorchard.com/privacy-policy/" target="_blank" rel="noopener">
          Privacy Policy/Your Privacy Rights
        </a>
        <div>|</div>
        <a href="https://www.sonymusic.com/how-we-use-your-data/" target="_blank" rel="noopener">
          How We Use Your Data
        </a>
        <div>|</div>
        <a href="https://www.sonymusic.com/ccpa-contact-form/" target="_blank" rel="noopener">
          Do Not Sell My Personal Information
        </a>
        <div>|</div>
        <a href="https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights" target="_blank" rel="noopener">
          Your California Privacy Rights
        </a>
        <div>|</div>
        <a href="https://www.theorchard.com/terms-conditions/" target="_blank" rel="noopener">
          Terms and Conditions
        </a>
      </div>
    </div>
  )
}