import { useEffect, useRef } from "react"

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { BokehPass } from 'three/addons/postprocessing/BokehPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { geoInterpolate } from 'd3-geo'

let scene, camera, renderer, composer, clock, controls, textureLoader, modelLoader, tempVector, animationLoop, raycaster, pointer;

let globeGroup, globe;
const globeRadius = 100;

let markerModel;
const markers = [];
const curves = [];
const curveDivisions = 20;
// const curveSpeed = 0.001;

let autoRotate = true;
let rotationSpeed = 0.001;

let grabbing = false;

export default function Globe(props) {
  const sceneRef = useRef();

  useEffect(() => {
    initGraphics();
    initControls();
    initPostprocessing();
    animate();
  }, [])

  useEffect(() => {
    if (props.galleryOpen) {
      autoRotate = false;
    } else {
      autoRotate = true;
    }
  }, [props.galleryOpen])

  const initGraphics = () => {
    clock = new THREE.Clock();
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.z = globeRadius * 3;

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;

    sceneRef.current.appendChild(renderer.domElement);
    window.addEventListener('resize', resize, false);

    // scene.fog = new THREE.Fog(0xBABABA, 10, 5000);
    scene.background = new THREE.Color(0x07080B);

    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();
    tempVector = new THREE.Vector3();

    // LIGHTS //
    const light = new THREE.AmbientLight( 0xFFFFFF, 0.7 ); // soft white light
    scene.add( light );

    const directionalLight = new THREE.DirectionalLight( 0xFFFFFF, 0.6);
    scene.add( directionalLight );

    // MODELS //
    textureLoader = new THREE.TextureLoader();
    
    modelLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); 
    modelLoader.setDRACOLoader( dracoLoader );

    modelLoader.load('./models/peso-pluma-marker.glb', gltf => {
        const world = gltf.scene;
        markerModel = world.getObjectByName('marker');

        markerModel.scale.set(1,1,1)





        globeGroup = new THREE.Group();

        const geometry = new THREE.SphereGeometry(globeRadius, 40, 30);
        const mapTexture = textureLoader.load( './textures/peso-pluma-globe_v11.jpg' );
        const material = new THREE.MeshBasicMaterial( { 
          map: mapTexture,
          wireframe: false,
          // emissiveMap: mapTexture
        });
        globe = new THREE.Mesh( geometry, material );
        globe.rotation.y = Math.PI;
        globeGroup.rotation.y = 2.5;
        globeGroup.rotation.x = 0.3;
        globeGroup.add(globe);
    
    
        props.cities.forEach((city, index) => {
          addMarker(city.lat, city.lng, city.name);
        })
    
        scene.add(globeGroup)
        
    
        markers.forEach((marker, index) => {
          markers.forEach((targetMarker, targetIndex) => {
            if (targetIndex !== index) {
              connectMarkers(marker, targetMarker);
    
              // const duplicates = 0 //10;
              // for (let i = 1; i < duplicates; i ++) {
              //   let minOffset, maxOffset;
    
              //   const multiplier = Math.random() < 0.5 ? -i : i;
              //   if (i < 3) {
              //     minOffset = 0.2 * multiplier;
              //     maxOffset = 0.3 * multiplier;
              //   } else {
              //     minOffset = 0.002 * multiplier;
              //     maxOffset = 0.001 * multiplier;
              //   }
    
              //   const randomOffset = Math.random() * (maxOffset - minOffset) + minOffset;
    
              //   const double = {
              //     userData: {
              //       lat: marker.userData.lat + randomOffset,
              //       lng: marker.userData.lng + randomOffset,
              //     },
              //     position: new THREE.Vector3(marker.position.x + randomOffset, marker.position.y + randomOffset, marker.position.z + randomOffset)
              //   }
              //   const doubleTarget = {
              //     userData: {
              //       lat: targetMarker.userData.lat + randomOffset,
              //       lng: targetMarker.userData.lng + randomOffset,
              //     },
              //     position: new THREE.Vector3(targetMarker.position.x + randomOffset, targetMarker.position.y + randomOffset, targetMarker.position.z + randomOffset)
              //   }
              //   connectMarkers(double, doubleTarget);
              // }
            }
          })
        })
        
        props.handleModelsLoaded();



    })

    
  }

  const initPostprocessing = () => {
    const renderScene = new RenderPass( scene, camera );
    const params = {
      threshold: 0.3,
      strength: 0.5,
      radius: 0.1,
      exposure: 0.2
    }
    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    bloomPass.threshold = params.threshold;
    bloomPass.strength = params.strength;
    bloomPass.radius = params.radius;

    const outputPass = new OutputPass();

    composer = new EffectComposer( renderer );
    composer.addPass( renderScene );
    composer.addPass( bloomPass );
    composer.addPass( outputPass );
  }

  let count = 0;
  const animate = () => {
    requestAnimationFrame(animate);
    controls.update();

    if (globeGroup && autoRotate) {
      globeGroup.rotation.y += rotationSpeed;
    }

    if (globe) {
      const cameraDistance = camera.position.distanceTo(globe.position);
      
      if (cameraDistance < 150) {
        markers.forEach(marker => {
          marker.scale.set(0.5, 0.5, 0.5);
        })
        rotationSpeed = 0.0002;
      } else if (cameraDistance < 200) {
        markers.forEach(marker => {
          marker.scale.set(0.7, 0.7, 0.7);
        })
        rotationSpeed = 0.0002;
      } else {
        markers.forEach(marker => {
          marker.scale.set(1, 1, 1);
        })
        rotationSpeed = 0.001;
      }
    }

    // curves.forEach(curve => {
    //   if (count <= curveDivisions) {
    //     curve.geometry.setDrawRange(0, count += curveSpeed);
    //   }
    // })

    // renderer.render(scene, camera)
    composer.render();
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }



  const getPositionFromCoords = (lat,lng, radius) => {
    const phi = (90 - lat) * Math.PI / 180;
    const theta = (180 - lng) * Math.PI / 180;

    const x = radius * Math.sin(phi) * Math.cos(theta);
    const z = radius * Math.sin(phi) * Math.sin(theta);
    const y = radius * Math.cos(phi);

    return new THREE.Vector3(x,y,z);
  }

  const addMarker = (lat, lng, city) => {
    // // const markerGeo = new THREE.PlaneGeometry(markerSize, markerSize, 1);
    // const markerGeo = new THREE.BoxGeometry(markerSize, markerSize * 2, markerSize);

    // const markerTexture = textureLoader.load( './textures/marker.png' );
    // // const markerMat = new THREE.MeshBasicMaterial({transparent: true, map: markerTexture, side: THREE.DoubleSide});
    // const markerMat = new THREE.MeshBasicMaterial();
    // const marker = new THREE.Mesh(markerGeo, markerMat);

    const marker = markerModel.clone();

    const position3D = getPositionFromCoords(lat,lng, globeRadius);

    marker.position.copy(position3D);

    // if (lat > 0) {
    //   marker.position.y += 5;
    // } else if (lat < 0 && lng > 0) {
    //   marker.position.z += 5;
    // } else {
    //   marker.position.z -= 10;
    // }
    
    let normal = new THREE.Vector3().copy( position3D )
    normal.normalize() // remove sphere translation
    
    marker.lookAt(normal);
    
    marker.userData.lat = lat;
    marker.userData.lng = lng;
    marker.userData.city = city;
    // marker.rotation.set(Math.PI / 2, Math.PI / 2, Math.PI / 2);
    globeGroup.add(marker);

    markers.push(marker);
  }

  function clamp(num, min, max) {
    return num <= min ? min : (num >= max ? max : num);
  }

  const connectMarkers = (start, end) => {
    const startLat = start.userData.lat;
    const startLng = start.userData.lng;
    const startPos = start.position;
    const endLat = end.userData.lat;
    const endLng = end.userData.lng;
    const endPos = end.position;

    

    const CURVE_MIN_ALTITUDE = 1;
    const CURVE_MAX_ALTITUDE = 15;
    const pointDistance = startPos.distanceTo(endPos);

    if (pointDistance > 20) {
      return
    }

    let altitude = clamp(pointDistance * .75, CURVE_MIN_ALTITUDE, CURVE_MAX_ALTITUDE);

    if (pointDistance > 130) {
      altitude = 30;
    } else if (pointDistance < 60) {
      altitude = 5;
    }

    // This function takes coords in reverse order (lng, lat)
    const interpolate = geoInterpolate([startLng, startLat], [endLng, endLat]);
    const midCoord1 = interpolate(0.25);
    const midCoord2 = interpolate(0.75);
    const mid1 = getPositionFromCoords(midCoord1[1], midCoord1[0], globeRadius + altitude);
    const mid2 = getPositionFromCoords(midCoord2[1], midCoord2[0], globeRadius + altitude);

    const spline = new THREE.CubicBezierCurve3(startPos, mid1, mid2, endPos);
    
    const pointPositions = [];
    const pointColors = [];
    const points = spline.getPoints(curveDivisions)

    const colorStops = [];
    colorStops.push({
      color: new THREE.Color(1.0, 1.0, 1.0),
      position: 0
    })
    
    colorStops.push({
      color: new THREE.Color(0.5607843137254902, 0.22745098039215686, 0.3254901960784314), 
      position: 0.3
    })
    colorStops.push({
      color: new THREE.Color(0.29411764705882354, 0.2901960784313726, 0.47843137254901963), 
      position: 0.5
    })
    colorStops.push({
      color: new THREE.Color(0.5607843137254902, 0.22745098039215686, 0.3254901960784314), 
      position: 0.7
    })
    
    colorStops.push({
      color: new THREE.Color(1.0, 1.0, 1.0),
      position: 1
    })

    const colorSegments = colorStops.length - 1; // 3 points = 2 segments
    const segmentDivisions = curveDivisions / colorSegments;

    points.forEach((point, index) => {
      const jitterFactor = 0.05;
      const randomJitter = Math.random() * (jitterFactor - -jitterFactor) - -jitterFactor;
      pointPositions.push(point.x + randomJitter)
      pointPositions.push(point.y + randomJitter)
      pointPositions.push(point.z + randomJitter)
      
      const indexPercentage = index / curveDivisions;
      
      colorStops.forEach((stop, stopIndex) => {

        const previousStop = colorStops[stopIndex - 1];
        if (previousStop && indexPercentage >= previousStop.position && indexPercentage < stop.position) {
          const currentColor = new THREE.Color(previousStop.color);
          const targetColor = new THREE.Color(stop.color);
          const offset = segmentDivisions * (stopIndex - 1);
          const factor = (index - offset) / segmentDivisions;
          
          const lerpedColor = currentColor.lerp(targetColor, factor);
          pointColors.push(lerpedColor.r)
          pointColors.push(lerpedColor.g)
          pointColors.push(lerpedColor.b)
        } else if (indexPercentage === 1) {
          const lastStop = colorStops[colorStops.length - 1]
          pointColors.push(lastStop.color.r)
          pointColors.push(lastStop.color.g)
          pointColors.push(lastStop.color.b)
        }
      })
    })

    const vertices = new Float32Array(pointPositions);

    // const curveGeo = new THREE.BufferGeometry().setFromPoints( points );
    const curveGeo = new THREE.BufferGeometry();
    curveGeo.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

    const colors = new Float32Array(pointColors);

    curveGeo.setAttribute('color', new THREE.BufferAttribute( colors, 3));
    const curveMat = new THREE.LineBasicMaterial({ vertexColors: colors });
    // const curveMat = new THREE.LineBasicMaterial({ color: 0xFFFFFF });

    

    const curve = new THREE.Line(curveGeo, curveMat);

    // curveGeo.setDrawRange(0, 0);
    curves.push(curve);
    globeGroup.add(curve);
  }
















  const initControls = () => {
    sceneRef.current.addEventListener( 'pointermove', onPointerMove );
    sceneRef.current.addEventListener( 'click', handleClick );
    sceneRef.current.addEventListener( 'mousedown', grab);
    sceneRef.current.addEventListener( 'mouseup', release);
    
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.minDistance = globeRadius + 30;
    controls.maxDistance = globeRadius * 3;
  }

  const grab = () => {
    sceneRef.current.style.cursor = 'grabbing';
    grabbing = true;
  }
  const release = () => {
    sceneRef.current.style.cursor = 'grab';
    grabbing = false;
  }

  const updatePointer = (e) => {
    pointer.x = ( e.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( e.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );

    const markerIntersections = raycaster.intersectObjects(markers);
    if (markerIntersections.length > 0) {
      pointer.target = markerIntersections[ 0 ];

      if (!grabbing) {
        sceneRef.current.style.cursor = 'pointer';
        autoRotate = false;
      }
    } else {
      pointer.target = null;
      if (!grabbing) {
        sceneRef.current.style.cursor = 'grab';
        autoRotate = true;
      }
    }
  }
  
  const onPointerMove = (e) => {
    updatePointer(e)
  }

  const handleClick = (e) => {
    updatePointer(e);

    if (pointer && pointer.target && pointer.target.object) {
      const city = pointer.target.object.userData.city;
      props.openGallery(city);
    }
  }


  return <div ref={sceneRef} className='webgl-wrapper'></div>
}