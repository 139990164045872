import styles from './Header.module.css';
import Logo from "../Ui/Logo";
import Metric from '../Ui/Metric';

export default function Header(props) {
  return (
    <>  
      <div className={styles.metric} data-layout='right'>
        <Metric label='COUNTRIES' value={props.countryCount} />
      </div>
      <div className={styles.metric} data-layout='left'>
        <Metric label='CITIES' value={props.cityCount} />
      </div>
      <div className={styles.logo}><Logo /></div>
    </>
  )
}