import { useEffect, useState } from "react";
import styles from './Gallery.module.css';
import Modal from "../Ui/Modal";
import Artwork from "./Artwork";
import IconSquare from "../Ui/Icons/IconSquare";
import IconGrid from "../Ui/Icons/IconGrid";
import IconBack from "../Ui/Icons/IconBack";

export default function Gallery(props) {
  const [view, setView] = useState('grid');
  const [submissionCount, setSubmissionCount] = useState();
  const [submissions, setSubmissions] = useState([]);
  const [activeSubmission, setActiveSubmission] = useState();
  const [zoomEnabled, setZoomEnabled] = useState(false);
  
  useEffect(() => {
    filterSubmissions();
  }, [])

  const filterSubmissions = async () => {
    const matchingSubmissions = props.submissions.filter(x => x.city === props.city)
    setSubmissionCount(matchingSubmissions.length);
    setSubmissions(matchingSubmissions);
  }
  
  const changeView = (e) => {
    setView(e.target.dataset.view)
  }

  const openZoom = (e) => {
    const targetSubmission = submissions.find(x => x.image_url === e.target.dataset.imageurl);
    setActiveSubmission(targetSubmission)
    setZoomEnabled(true)
  }
  const closeZoom = () => {
    setActiveSubmission(null)
    setZoomEnabled(false)
  }

  return (
    <Modal
      active={props.active}
      allowClose={!zoomEnabled}
      close={props.closeGallery}
      mode='dark'
    >
      <h2>{props.city}</h2>

      {!zoomEnabled && (
        <>
          <div className={styles.controls}>
            <div>{submissionCount} Submissions</div>
            <div className={styles.viewBtns}>
              <button onClick={changeView} data-view='square' className='btn btn--text'>
                <IconSquare />
              </button>
              <button onClick={changeView} data-view='grid' className='btn btn--text'>
                <IconGrid />
              </button>
            </div>
          </div>

          <div className={styles.artWrapper} data-view={view}>
            {submissions.map((submission, index) => (
              <Artwork key={index} submission={submission} openZoom={openZoom} />
            ))}
          </div>
        </>
      )}

      {zoomEnabled && activeSubmission && (
        <div className={styles.zoomView}>
          <div onClick={closeZoom} className={`btn btn--text ${styles.zoomBack}`}> 
            <IconBack /> Back
          </div>
          <div className={styles.zoomImageWrap}>
            <img src={activeSubmission.image_url} />
          </div>
          <div className={styles.zoomLabel}>
            {activeSubmission.first_name} {activeSubmission.last_name ? activeSubmission.last_name.slice(0,1) : null}
          </div>
        </div>
      )}
    </Modal>
  )
}