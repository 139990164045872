import LoadingBar from '../Ui/LoadingBar';
import styles from './Welcome.module.css';

export default function Welcome(props) {
  return(
    <div className='screen' data-role="welcome">
      <div className='screen__content'>

        <img alt='site logo that reads El Mundo De La Doble P' src='./images/peso-logo_long_stacked_comp-2.png' className={styles.titleMobile} />
        <img alt='stacked site logo that reads El Mundo De La Doble P' src='./images/peso-logo_long_comp-2.png' className={styles.title} />
       
        <h2>Global Digital Art Installation</h2>

        <div>
        View the sounds of Peso Pluma echoing around the world. Experience fan art inspired by the Genesis album.
        </div>

        {/* <button className={`btn ${styles.submitBtn}`} onClick={props.openSubmissionForm}>SUBMIT ART</button> */}

        <div className={styles.cta} data-active={!props.loading}>
          <button className='btn' onClick={props.confirmWelcome}>ENTER SITE</button>
        </div>

        
        
        {props.loading && <LoadingBar />}
      </div>
    </div>
  )
}