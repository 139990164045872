
export default function Instructions(props) {
  return(
    <div className='screen'>
      <div className='screen__content'>

        <p>
          Drag the globe to rotate and explore.<br/>
          Click a marker to view artwork from that location.
        </p>

        <button onClick={props.enterSite} className='btn'>EXPLORE</button>
      </div>
    </div>
  )
}