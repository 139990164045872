import styles from './Metric.module.css'

export default function Metric(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img src='https://d3s2b0qq4f8hw0.cloudfront.net/images/spider.png' />
      </div>
      <div className={styles.label}>
        {props.label}
      </div>
      <div className={styles.value}>
        {props.value}
      </div>
    </div>
  )
}